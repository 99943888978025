<template>
  <v-container fluid>
    <carregando :opacidade="1" v-if="carregandoTela" />

    <v-col align-items="baseline" justify="center">
      <v-card class="d-flex justify-center row" elevation="0">
        <validation-provider vid="grupos">
          <v-card
            elevation="1"
            outlined
            class="flex-row d-flex pa-3 my-4 flex-wrap justify-center align-content-around"
          >
            <v-radio-group
              class="flex-row d-flex align-center"
              v-if="$store.getters['usuario/temPermissao']('listar.permissoes')"
            >
              <v-radio
                v-for="item in listaGrupos"
                :key="item.id"
                class="mr-2"
                :label="item.nome"
                :value="item.id"
                @click="$emit('Listar', listarPermissoesGrupo(item.id))"
              />
            </v-radio-group>
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao'](
                  'vincular.grupos.permissoes'
                )
              "
              :carregando="carregandoSalvar"
              :disabled="carregandoSalvar"
              class="d-flex align-center"
              @click="salvar()"
            />
          </v-card>
        </validation-provider>

        <validation-provider vid="grupos">
          <v-row class="cards">
            <v-col
              v-for="item in listAgrupadores"
              :key="item.id"
              xs="6"
              sm="6"
              md="6"
              lg="4"
              v-show="!(item.nome == 'PLANO')"
            >
              <v-card elevation="1" outlined :title="item">
                <v-card-title
                  @click="item.show = !item.show"
                  class="justify-center"
                >
                  <h5>{{ item.nome }}</h5>
                  <v-spacer />
                  <v-icon
                    >{{ item.show ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                </v-card-title>

                <v-expand-transition>
                  <div v-show="item.show">
                    <template v-for="permissoes in listaPermissoes">
                      <v-checkbox
                        :key="permissoes.id"
                        v-if="permissoes.agrupador == item.nome"
                        class="pa-2 ma-0"
                        v-model="permissaoNova.permissoes"
                        :readonly="somenteListar"
                        :disabled="$store.state.layout.loading"
                        :label="permissoes.nome"
                        :value="permissoes.id"
                      />
                    </template>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </validation-provider>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import grupos from "@/api/grupos";
import permissoes from "@/api/permissoes";
import store from "@/store";

export default {
  data() {
    return {
      carregandoTela: false,
      carregandoSalvar: false,

      somenteListar:
        store.getters["usuario/temPermissao"]("mostrar.grupos.permissoes") &&
        !store.getters["usuario/temPermissao"]("vincular.grupos.permissoes"),
      validacaoFormulario: {},
      listaGrupos: [],
      listaPermissoes: [],
      permissaoNova: {
        permissoes: [],
      },
      listAgrupadores: [],
    };
  },

  mounted() {
    this.permissoes().then(() => {
      this.gruposListar();
    });
    this.listarAgrupadores();
  },

  methods: {
    async permissoes() {
      this.$store.commit(`layout/SET_LOADING`, true);
      this.carregandoTela = true;
      try {
        const resposta = await permissoes.listar();
        this.listaPermissoes = resposta.data.data;
      } catch (e) {
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      }
    },

    async gruposListar() {
      try {
        const resposta = await grupos.listar();
        this.listaGrupos = resposta.data.data;
      } catch (e) {
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregandoTela = false;
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async listarPermissoesGrupo(id) {
      this.$store.commit(`layout/SET_LOADING`, true);
      try {
        this.permissaoNova.permissoes = [];
        this.permissaoNova.grupo = id;
        const resposta = await grupos.listarPermissoesGrupo(id);

        var permissoesGrupo = resposta.data.data;

        this.listaPermissoes.forEach((item) => {
          permissoesGrupo.forEach((item2) => {
            if (item.id === item2.id) {
              this.permissaoNova.permissoes.push(item.id);
            }
          });
        });
      } catch (e) {
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async listarAgrupadores() {
      try {
        const resposta = await permissoes.listarAgrupadores();
        this.listAgrupadores = resposta.data.data;
        let i = 0;
        while (i < this.listAgrupadores.length) {
          this.listAgrupadores[i] = {
            id: i,
            nome: this.listAgrupadores[i],
            show: false,
          };
          i++;
        }
      } catch (e) {
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      }
    },
    async salvar() {
      this.carregandoSalvar = true;

      try {
        var permissoes = {};
        permissoes.permissoes = this.permissaoNova.permissoes;

        await grupos.vincularPermissoesGrupo(
          this.permissaoNova.grupo,
          permissoes
        );

        this.$snackbar.mostrar({
          cor: "success",
          mensagem: this.$mensagens._("sucesso_adicionar"),
        });
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
<style scope>
.v-input--radio-group__input {
  margin: 0 !important;
  flex-flow: row wrap !important;
}

.v-radio {
  margin: 0 !important;
}

.row {
  margin: 0 !important;
}

.v-input__control .v-messages {
  display: none;
}

.v-input--selection-controls {
  margin: 0;
  padding: 0;
}

.v-input__slot {
  margin: 0;
}

@media screen and (max-width: 600px) {
  .cards .col {
    min-width: 80% !important;
  }
  .cards {
    height: auto !important;
  }

  .v-input--radio-group__input {
    flex-direction: column !important;
  }
}
</style>